import * as React from 'react';

const SvgIconSpinner = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-icon="1"
    {...props}
  >
    <path
      d="M1.5 20C0.671573 20 -0.00585396 20.6725 0.0562147 21.4986C0.313762 24.9264 1.45149 28.2392 3.37061 31.1114C5.56823 34.4004 8.69181 36.9638 12.3463 38.4776C16.0009 39.9913 20.0222 40.3874 23.9018 39.6157C27.7814 38.844 31.3451 36.9392 34.1421 34.1421C36.9392 31.3451 38.844 27.7814 39.6157 23.9018C40.3874 20.0222 39.9913 16.0009 38.4776 12.3463C36.9638 8.69181 34.4004 5.56824 31.1114 3.37061C28.2392 1.45149 24.9264 0.313763 21.4986 0.056215C20.6725 -0.00585384 20 0.671573 20 1.5C20 2.32843 20.6729 2.99313 21.4981 3.06613C24.3318 3.31681 27.066 4.27566 29.4447 5.86502C32.2403 7.733 34.4193 10.388 35.706 13.4944C36.9926 16.6007 37.3293 20.0189 36.6734 23.3165C36.0174 26.6142 34.3983 29.6433 32.0208 32.0208C29.6433 34.3983 26.6142 36.0174 23.3165 36.6734C20.0189 37.3293 16.6007 36.9926 13.4944 35.706C10.388 34.4193 7.733 32.2403 5.86502 29.4447C4.27565 27.066 3.3168 24.3318 3.06613 21.4981C2.99313 20.6729 2.32843 20 1.5 20Z"
      fill="#EB0A1E"
    />
  </svg>
);

export default SvgIconSpinner;
